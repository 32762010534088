import React from 'react';

const AvvoBadge = ({avvoCode}) => {
    return (
        <div dangerouslySetInnerHTML={{__html: `${avvoCode}`}}>
        </div>
    );
}



export default AvvoBadge;